<template>
  <v-container>
    <v-row>
      <v-col>
        <div>
          <h1 class="ml-2 mt-2" style="font-weight: 200">
            Establ. {{ establishmentName }}
          </h1>
          <div class="d-flex">
            <v-btn
              x-small
              class="grey--text ml-2"
              text
              elevation="0"
              @click="() => this.$router.push({ path: '/establishment' })"
              >All Establishments</v-btn
            >
          </div>
          <div class="d-flex flex-column ml-4 mr-4 mt-4">
            <div v-if="!editMode">
              <v-text-field
                :value="singleEstablishment.id"
                label="ID"
                class="managrx-input mr-2"
                outlined
                dense
                readonly
                filled
              ></v-text-field>
              <v-text-field
                :value="singleEstablishment.name"
                label="Nombre"
                class="managrx-input mr-2"
                outlined
                dense
                readonly
                filled
              ></v-text-field>
              <v-text-field
                :value="singleEstablishment.hectares"
                label="Hectáreas"
                class="managrx-input mr-2"
                type="number"
                outlined
                dense
                readonly
                filled
              ></v-text-field>

              <v-select
                outlined
                dense
                filled
                readonly
                :value="singleEstablishment.region"
                :items="regions"
                item-text="name"
                item-value="id"
              >
              </v-select>
            </div>
            <div v-if="editMode">
              <v-text-field
                :value="establishmentCopy.id"
                label="ID"
                class="managrx-input mr-2"
                outlined
                dense
                filled
                readonly
              ></v-text-field>
              <v-text-field
                v-model="establishmentCopy.name"
                label="Nombre"
                class="managrx-input mr-2"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model.number="establishmentCopy.hectares"
                label="Hectáreas"
                class="managrx-input mr-2"
                type="number"
                outlined
                dense
              ></v-text-field>

              <v-select
                outlined
                dense
                v-model="establishmentCopy.region"
                :items="regions"
                item-text="name"
                item-value="id"
              >
              </v-select>
            </div>

            <div class="d-flex">
              <v-btn
                color="warning"
                class="mr-2"
                @click="enterEditMode"
                v-if="!editMode"
              >
                <v-icon> mdi-pencil</v-icon></v-btn
              >

              <v-btn
                color="primary white--text"
                @click="saveClick"
                v-if="editMode"
                class="mr-2"
              >
              <v-icon> mdi-floppy</v-icon></v-btn
              >
              <v-btn
                color="blue white--text"
                class="mr-2"
                @click="exitEditMode"
                v-if="editMode"
              >
                Atrás</v-btn
              >
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import Table from "../components/Table";
import { mapGetters } from "vuex";

import { putRequest } from "../managrx/serverCall";

export default {
  //

  computed: {
    ...mapGetters(["singleEstablishment", "regions"]),
    establishmentName() {
      return this.singleEstablishment?.name;
    },
  },

  mounted() {
    this.$store.dispatch("getSingleEstablishment", this.$route.params.id);
  },
  methods: {
    enterEditMode() {
      this.establishmentCopy = { ...this.singleEstablishment };
      this.editMode = true;
    },
    exitEditMode() {
      this.editMode = false;
    },
    async saveClick() {
      let updates = {
        name: this.establishmentCopy.name,
        hectares: this.establishmentCopy.hectares,
        regionId: this.establishmentCopy.region?.id
          ? this.establishmentCopy.region.id
          : this.establishmentCopy.region,
      };

      let response = await putRequest(
        "/establishment/" + this.$route.params.id,
        updates
      );

      if(!response.success){
        this.$store.dispatch("setNewNotification", response)
      }else {
        this.$store.dispatch("getSingleEstablishment", this.$route.params.id);
      }
        this.editMode = false;
    },
  },
  data() {
    return {
      editMode: false,
      establishmentCopy: {},
    };
  },
};
</script>
